export const DEFAULT_LAYOUT = Object.freeze({
  lg: [
    {
      x: 0,
      y: 0,
      w: 6,
      h: 12,
      minW: 6,
      minH: 6,
      i: '0',
      name: 'tradingViewVisible',
      component: 'TradingView'
    },
    { x: 6, y: 0, w: 3, h: 6, minW: 3, minH: 6, i: '1', name: 'orderBookVisible', component: 'DerivativeInfos' },
    { x: 6, y: 6, w: 3, h: 6, minW: 3, minH: 6, i: '2', name: 'recentTradeVisible', component: 'RecentTrade' },
    { x: 9, y: 0, w: 3, h: 12, minW: 3, minH: 12, i: '3', name: 'terminalVisible', component: 'TradeOrderForm' },
    { x: 0, y: 12, w: 6, h: 12, minW: 3, minH: 9.47, i: '4', name: 'positionVisible', component: 'PositionOrders' },
    { x: 6, y: 12, w: 6, h: 12, minW: 3, minH: 6, i: '5', name: 'tableVisible', component: 'TradeRecords' },
    {
      x: 0,
      y: 12,
      w: 6,
      h: 12,
      minW: 4,
      minH: 12,
      i: '6',
      name: 'exchangeInfoVisible',
      component: 'ExchangeComparison'
    }
  ],

  md: [
    {
      x: 0,
      y: 0,
      w: 12,
      h: 12,
      minW: 12,
      minH: 12,
      i: '0',
      name: 'tradingViewVisible',
      component: 'TradingView'
    },
    { x: 0, y: 12, w: 12, h: 12, minW: 12, minH: 12, i: '1', name: 'terminalVisible', component: 'TradeOrderForm' },
    { x: 0, y: 24, w: 12, h: 12, minW: 12, minH: 12, i: '2', name: 'orderBookVisible', component: 'DerivativeInfos' },
    { x: 0, y: 36, w: 12, h: 12, minW: 12, minH: 12, i: '3', name: 'recentTradeVisible', component: 'RecentTrade' },
    {
      x: 0,
      y: 48,
      w: 12,
      h: 12,
      minW: 12,
      minH: 12,
      i: '4',
      name: 'exchangeInfoVisible',
      component: 'ExchangeComparison'
    },
    { x: 0, y: 60, w: 12, h: 12, minW: 12, minH: 12, i: '5', name: 'positionVisible', component: 'PositionOrders' },
    { x: 0, y: 72, w: 12, h: 12, minW: 12, minH: 12, i: '6', name: 'tableVisible', component: 'TradeRecords' }
  ],

  sm: [
    {
      x: 0,
      y: 0,
      w: 12,
      h: 12,
      minW: 12,
      minH: 12,
      i: '0',
      name: 'tradingViewVisible',
      component: 'TradingView'
    },
    { x: 0, y: 12, w: 12, h: 12, minW: 12, minH: 12, i: '1', name: 'terminalVisible', component: 'TradeOrderForm' },
    { x: 0, y: 24, w: 12, h: 12, minW: 12, minH: 12, i: '2', name: 'orderBookVisible', component: 'DerivativeInfos' },
    { x: 0, y: 36, w: 12, h: 12, minW: 12, minH: 12, i: '3', name: 'recentTradeVisible', component: 'RecentTrade' },
    {
      x: 0,
      y: 48,
      w: 12,
      h: 12,
      minW: 12,
      minH: 12,
      i: '4',
      name: 'exchangeInfoVisible',
      component: 'ExchangeComparison'
    },
    { x: 0, y: 60, w: 12, h: 12, minW: 12, minH: 12, i: '5', name: 'positionVisible', component: 'PositionOrders' },
    { x: 0, y: 72, w: 12, h: 12, minW: 12, minH: 12, i: '6', name: 'tableVisible', component: 'TradeRecords' }
  ]
})
